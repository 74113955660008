import 'jsoneditor-react/es/editor.min.css'
import React, { useRef, useEffect } from 'react'

import { JsonEditor as Editor } from 'jsoneditor-react'

interface IProps {
  value?: any
  onChange?: () => void
  mode?: 'tree' | 'view' | 'form' | 'code' | 'text'
}

const JsonEditor = (props: IProps) => {
  const { value, onChange, mode } = props
  const jsonEditorRef: any = useRef(null)

  useEffect(() => {
    if (jsonEditorRef.current !== null) jsonEditorRef.current.update(value)
  }, [value])

  const setRef = (instance) => {
    jsonEditorRef.current = instance?.jsonEditor || null
  }

  return (
    <Editor
      mode={mode}
      preview
      ref={setRef}
      value={value || {}}
      onChange={onChange}
      theme='ace/theme/github'
    />
  )
}

export default JsonEditor
