enum RoutesLabel {
  TransactionHistory = 'Transaction History',
  PurchaseHistory = 'Exchange Reward History',
  GoldTransfer = 'Noctua Gold Transfer',
  NoctuaTransactionHistory = 'Noctua Transaction',
  NoctuaCoinTransactionHistory = 'Noctua Point Transaction',
  NoctuaGoldTransactionHistory = 'Noctua Gold Transaction',
  CoinLogHistory = 'Point Transaction History',
  GachaTokenHistory = 'Gacha Token',
  MileStoneHistory = 'Milestone',
  Users = 'User',
  UserList = 'User List',
  Topup = 'Top Up',
  Banner = 'Banner',
  Game = 'Game',
  NewsAndReport = 'News And Updates',
  Launcher = 'Launcher',
  Metadata = 'Metadata'
}

export default RoutesLabel
