/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Row, Modal, Col, Input } from 'antd'
import { EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { ReduxState } from '../../store/reducers'
import Actions from '../../store/actions'
import Layout from '../../components/layout'
import IMetadataState from '../../interfaces/states/metadata'
import MetadataColumn from '../../constants/table/metadata'
import DefaultForm from '../../components/form'
import MetadataFormColumn from '../../constants/form/metadata'
import { isJsonString } from '../../utils'
import IMetadata from '../../interfaces/models/metadata'
import useDebounce from '../../utils/use-debounce'
import MetadataList from './list'
import ButtonAction from '../../components/button-action'
import IAuthState from '../../interfaces/states/auth'
import USER_ACTION from '../../constants/user-action'

interface IProps {
  GetList: (types?: string, page?: number) => void
  Create: (data: IMetadata) => Promise<any>
  Update: (data: IMetadata) => Promise<any>
  Delete: (id: number) => void
  metadata: IMetadataState
  auth: IAuthState
}

const Metadata = (props: IProps) => {
  const { GetList, Create, Update, Delete, metadata, auth } = props
  const [initValues, setInitialValues] = React.useState<IMetadata>()
  const [showForm, setShowForm] = React.useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    undefined
  )
  const value = useDebounce(searchValue, 500)

  const onReset = () => {
    setInitialValues({ ...({} as IMetadata) })
  }

  const onCloseForm = () => {
    onReset()
    setShowForm(false)
  }

  useEffect(() => {
    GetList(value)
  }, [value])

  return (
    <Layout header={{ title: 'Metadata' }}>
      <Row justify='end' className='mb-2'>
        <Button
          type='primary'
          icon={<PlusOutlined />}
          onClick={() => {
            onReset()
            setShowForm(true)
          }}
        >
          Add Metadata
        </Button>
      </Row>
      <MetadataList
        auth={auth}
        metadata={metadata}
        onClickEdit={(record) => {
          setShowForm(true)
          setInitialValues(record)
        }}
      />
      <Modal
        visible={showForm}
        onCancel={() => setShowForm(false)}
        footer={null}
        title='Metadata Form'
      >
        <DefaultForm
          className='metadata-form'
          formName='metadata-form'
          visible={showForm}
          rowProps={{
            gutter: 16
          }}
          colProps={{
            span: 24
          }}
          confirmLoading={metadata.requesting}
          okText='Save'
          fields={[
            ...MetadataFormColumn,
            {
              label: 'Value',
              name: 'value',
              type: 'json-editor'
            }
          ]}
          initialValues={{
            ...initValues,
            value: isJsonString(initValues?.value || '{}')
              ? JSON.parse(initValues?.value || '{}')
              : initValues?.value
          }}
          onCancel={onCloseForm}
          additionalElementForm={
            <Row justify='end' className='mt-2'>
              <Col xs={24} md={8}>
                <ButtonAction
                  level={auth.ruleActivePage.level}
                  action={
                    initValues?.id ? USER_ACTION.UPDATE : USER_ACTION.CREATE
                  }
                  type='primary'
                  size='large'
                  htmlType='submit'
                  block
                  loading={metadata.requesting}
                >
                  Save
                </ButtonAction>
              </Col>
            </Row>
          }
          onFinish={(values) => {
            const data = {
              ...values,
              value: JSON.stringify(values.value)
            }
            const promise = initValues?.id ? Update(data) : Create(data)
            promise.then((res) => {
              if (res?.data?.success) {
                onCloseForm()
                GetList(value)
              }
            })
          }}
        />
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  metadata: state.metadata,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: any) => ({
  GetList: (types?: string, page?: number) =>
    dispatch(Actions.Metadata.GetList(types, page)),
  Create: (data: IMetadata) => dispatch(Actions.Metadata.Create(data)),
  Update: (data: IMetadata) => dispatch(Actions.Metadata.Update(data)),
  Delete: (id: number) => dispatch(Actions.Metadata.Delete(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Metadata)
