import {
  Avatar,
  Button,
  Col,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table
} from 'antd'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import IUser from '../../../interfaces/models/user'
import Text from 'antd/lib/typography/Text'
import { ColumnsType } from 'antd/lib/table'
import { DeleteTwoTone, FormOutlined, SearchOutlined } from '@ant-design/icons'
import UserAPI from '../../../store/apis/user'
import { Item } from '../../../constants/enum/item'
import { ASSETS_USERINFO } from '../../../constants/assets'
import {
  reason_options,
  REASON_TYPE
} from '../../../constants/enum/reason-type'
import { onCapitalize } from '../../../utils/formatting'
import SendItemNote from './note'
import { ISendItem } from './type'
import { generateTransactionID } from '../../../utils/generator'
import { CreateBulkGoldLogs } from '../../../store/apis/account/gold-log'
import { CreateBulkGachaLogs } from '../../../store/apis/account/gacha-token-log'
import { debounce } from 'lodash'

interface IProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const { Option } = Select

export default function SendItemModal({ isOpen, setIsOpen }: IProps) {
  const searchValueRef = useRef<string | undefined>(undefined)
  const [isFetchUserLoading, setIsFetchUserLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [itemSelected, setItemSelected] = useState<Item>(Item.noctuaGold)
  const [searchValue, setSearchValue] = useState<string>('')
  const [dataSearch, setDataSearch] = useState<IUser[]>([])
  const [selectedItem, setSelectedItem] = useState<ISendItem | undefined>(
    undefined
  )
  const [selectedItems, setSelectedItems] = useState<ISendItem[]>([])

  const debouncedSearchValue = useRef(
    debounce((value: string) => {
      searchValueRef.current = value
      if (!value.length) {
        setDataSearch([])
        return
      }

      const getUsers = async () => {
        setIsFetchUserLoading(true)
        const resp = await UserAPI.GetList(1, 5, value)
        const data = (resp?.data?.data || []) as IUser[]
        setDataSearch(data)
        setIsFetchUserLoading(false)
      }

      getUsers()
    }, 300)
  ).current

  useEffect(() => {
    if (searchValueRef.current !== searchValue) {
      debouncedSearchValue(searchValue)
    }

    // Cleanup debounce on unmount
    return () => {
      debouncedSearchValue.cancel()
    }
  }, [searchValue, debouncedSearchValue])

  const resetAll = useCallback(() => {
    setItemSelected(Item.noctuaGold)
    setSearchValue('')
    setDataSearch([])
    setSelectedItem(undefined)
    setSelectedItems([])
  }, [setItemSelected, setSearchValue, setDataSearch, setSelectedItems])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    resetAll()
  }, [resetAll, setIsOpen])

  const handleSelectItem = useCallback(
    (user: IUser) => {
      const data = selectedItems.find(
        (selectedData) => selectedData.user.id === user.id
      )
      if (data) return

      setSelectedItems((prevData) => [{ user, totalItem: 0 }, ...prevData])
    },
    [selectedItems]
  )

  const handleUpdateNote = useCallback(
    (note: string) => {
      setSelectedItems((prevState) =>
        prevState.map((state) =>
          state.user.id === selectedItem?.user.id
            ? { ...selectedItem, note }
            : state
        )
      )
      setSelectedItem(undefined)
    },
    [selectedItem]
  )

  const handleUpdateTrxType = (id: number, trxType: REASON_TYPE) => {
    setSelectedItems((prevState) =>
      prevState.map((state) =>
        state.user.id === id ? { ...state, trxType } : state
      )
    )
  }

  const handleTotalItem = (id: number, totalItem: number) => {
    setSelectedItems((prevData) =>
      prevData.map((pD) => (pD.user.id === id ? { ...pD, totalItem } : pD))
    )
  }

  const handleRemoveItem = (id: number) => {
    setSelectedItems((prevData) => prevData.filter((pD) => pD.user.id !== id))
  }

  const handleSendingNoctuaGold = useCallback(async () => {
    const currentDate: Date = new Date()
    const timestamp: number = currentDate.getTime()
    const payload = selectedItems.map((item) => ({
      userId: item.user.id,
      amount: item.totalItem,
      transactionId: generateTransactionID(timestamp.toString(), item.trxType!),
      reason: item.trxType!,
      note: item.note || ''
    }))
    const resp = await CreateBulkGoldLogs(payload)
    if (resp?.status === 200 || resp?.data?.success) {
      message.success(`Successfully sending gold`)
      handleClose()
    }
  }, [selectedItems, handleClose])

  const handleSendingTicket = useCallback(async () => {
    const currentDate: Date = new Date()
    const timestamp: number = currentDate.getTime()
    const payload = selectedItems.map((item) => ({
      userId: item.user.id,
      amount: item.totalItem,
      transactionId: generateTransactionID(timestamp.toString(), item.trxType!),
      reason: item.trxType!,
      note: item.note || ''
    }))
    const resp = await CreateBulkGachaLogs(payload)
    if (resp?.status === 200 || resp?.data?.success) {
      message.success(`Successfully sending ticket`)
      handleClose()
    }
  }, [selectedItems, handleClose])

  const handleSendingItem = useCallback(() => {
    setIsLoading(true)
    const isValid = !selectedItems.some(
      (data) => data.totalItem === 0 || !data.trxType
    )
    if (!isValid) {
      message.error(`All item must be filled!`)
      setIsLoading(false)
      return
    }

    if (itemSelected === Item.noctuaGold) handleSendingNoctuaGold()
    if (itemSelected === Item.ticket) handleSendingTicket()

    setIsLoading(false)
  }, [
    selectedItems,
    itemSelected,
    handleSendingNoctuaGold,
    handleSendingTicket
  ])

  const columns: ColumnsType<ISendItem> = useMemo(() => {
    return [
      {
        title: <span style={{ fontSize: 10 }}>Noctua ID</span>,
        key: 'id',
        render: (record: ISendItem) => {
          return (
            <Row key={record.user.id}>
              <Text className='text-size-12 text-height-20'>
                {record.user.id}
              </Text>
            </Row>
          )
        }
      },
      {
        title: <span style={{ fontSize: 10 }}>Email</span>,
        key: 'email',
        render: (record: ISendItem) => {
          return (
            <div
              style={{
                maxWidth: 100
              }}
            >
              <Text className='text-size-12 text-height-20'>
                {record?.user.email}
              </Text>
            </div>
          )
        }
      },
      {
        title: (
          <span style={{ width: '100%', fontSize: 10 }}>Transaction Type</span>
        ),
        key: 'trxType',
        render: (record: ISendItem) => {
          return (
            <div style={{ width: 100 }}>
              <Select
                value={record.trxType}
                style={{ width: '100%', fontSize: 10 }}
                dropdownStyle={{
                  fontSize: 10,
                  width: 30
                }}
                onChange={(value) => handleUpdateTrxType(record.user.id, value)}
              >
                {reason_options.map((item, itemIdx) => (
                  <Option key={itemIdx} value={item} style={{ fontSize: 10 }}>
                    {onCapitalize(item).replaceAll('-', ' ')}
                  </Option>
                ))}
              </Select>
            </div>
          )
        }
      },
      {
        title: <span style={{ fontSize: 10 }}>Note</span>,
        key: 'note',
        render: (record: ISendItem) => {
          return (
            <div
              className='flex items-center'
              style={{
                width: '100%'
              }}
            >
              <FormOutlined onClick={() => setSelectedItem(record)} />
            </div>
          )
        }
      },
      {
        title: <span style={{ fontSize: 10 }}>Total</span>,
        key: 'total',
        render: (record: ISendItem) => {
          return (
            <div
              style={{
                display: 'flex',
                gap: 10,
                alignItems: 'center'
              }}
            >
              <InputNumber
                min={0}
                style={{
                  width: 60
                }}
                value={record?.totalItem}
                onChange={(e) => handleTotalItem(record?.user.id, e)}
              />
              <DeleteTwoTone
                twoToneColor='#d11a2a'
                style={{
                  fontSize: 20
                }}
                onClick={() => handleRemoveItem(record?.user.id)}
              />
            </div>
          )
        }
      }
    ]
  }, [])

  return (
    <Modal
      visible={isOpen}
      title={<h3 style={{ color: '#0d43fb' }}>Send Item</h3>}
      onCancel={handleClose}
      closeIcon={null}
      footer={null}
    >
      {selectedItem ? (
        <SendItemNote
          item={selectedItem}
          handleCancelItem={() => setSelectedItem(undefined)}
          handleSaveItem={handleUpdateNote}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <Radio.Group
              value={itemSelected}
              onChange={(e) => setItemSelected(e.target.value)}
            >
              <Radio.Button value={Item.noctuaGold}>
                <div
                  className='flex items-center align-center'
                  style={{
                    gap: 5
                  }}
                >
                  <Image
                    src={'/images/' + ASSETS_USERINFO.NOCTUA_GOLD}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <Text>Noctua Gold</Text>
                </div>
              </Radio.Button>
              <Radio.Button value={Item.ticket}>
                <div
                  className='flex items-center align-center'
                  style={{
                    gap: 5
                  }}
                >
                  <Image
                    src={'/images/' + ASSETS_USERINFO.GACHA_TICKET}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <Text>Ticket</Text>
                </div>
              </Radio.Button>
            </Radio.Group>
            <Input
              style={{ width: '200px' }}
              placeholder='search'
              suffix={<SearchOutlined />}
              value={searchValue}
              size='middle'
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {isFetchUserLoading && (
            <div
              className='flex'
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 20,
                paddingBottom: 20
              }}
            >
              <Spin spinning />
            </div>
          )}
          {!isFetchUserLoading && !!dataSearch.length && (
            <Row gutter={[0, 10]} className='user-card'>
              {dataSearch.map((data) => (
                <Col
                  className='flex align-center'
                  style={{
                    gap: 5,
                    borderRadius: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                    paddingLeft: 10,
                    paddingRight: 10
                  }}
                  span={12}
                  key={data.id}
                  onClick={() => handleSelectItem(data)}
                >
                  <Avatar src={data?.configuration?.image} size={32} />
                  <div className='flex flex-vertical'>
                    <Text className='text-size-12'>ID: {data?.id}</Text>
                    <Text className='text-size-12'>{data?.email}</Text>
                  </div>
                </Col>
              ))}
            </Row>
          )}
          <Table
            style={{ width: '100%' }}
            columns={columns}
            dataSource={selectedItems}
            rowKey={(data: ISendItem) => data.user.id}
            pagination={{
              hideOnSinglePage: true,
              showSizeChanger: false,
              pageSize: 5
            }}
          />
          <div
            className='flex flex-vertical'
            style={{
              gap: 5
            }}
          >
            <Button
              loading={isLoading}
              type='primary'
              disabled={!selectedItems.length}
              onClick={handleSendingItem}
            >
              Send {itemSelected === Item.noctuaGold ? 'Noctua Gold' : 'Ticket'}
            </Button>
            <span
              style={{
                fontSize: 10
              }}
            >
              <strong>*Note:</strong> Please make sure all user data before
              sending item
            </span>
          </div>
        </div>
      )}
    </Modal>
  )
}
