import STATUS from '../../constants/enum/order-status'
import { Tag as TagSlot } from 'antd'
import React from 'react'
export default function Tag({ status }: any) {
  switch (status) {
    case STATUS.DELIVERED.toLowerCase(): {
      return <TagSlot color='success'>{status}</TagSlot>
    }
    case STATUS.FAILED.toLowerCase(): {
      return <TagSlot color='error'>{status}</TagSlot>
    }
    default: {
      return <TagSlot color='processing'>{status}</TagSlot>
    }
  }
}
