/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from 'antd'
import React, { useCallback, useEffect } from 'react'
import IUserState from '../../../interfaces/states/user'
import SectionUserInfo from './section-user-info'
import GoldHistory from '../../noctua-gold-transaction/gold-history/list'
import Gacha from '../../exchange-reward/gacha'
import PointHistory from '../../noctua-point-history/list'
import PurchaseHistory from '../../purchase-history'
import SELL_TYPE from '../../../constants/enum/sell-type'
import MileStoneHistory from '../../milestone-history/list'
import IAuthState from '../../../interfaces/states/auth'
export default function UserInfo({
  auth,
  user,
  GetUserProfile,
  paramsId
}: IUserInfoProps) {
  const TabPane = Tabs.TabPane

  const onFetchUserProfile = useCallback(
    (id: number) => {
      GetUserProfile(id)
    },
    [GetUserProfile]
  )

  const items = [
    {
      key: '1',
      label: 'Noctua Gold',
      children: <GoldHistory userID={parseInt(paramsId || '')} />
    },
    {
      key: '2',
      label: 'Noctua Point',
      children: <PointHistory userID={parseInt(paramsId || '')} />
    },
    {
      key: '3',
      label: 'Gacha Ticket',
      children: (
        <Gacha
          layout={{
            usingLayout: false,
            title: false,
            filter: false
          }}
          userID={parseInt(paramsId || '')}
        />
      )
    },
    {
      key: '4',
      label: 'Item Rewards',
      children: (
        <PurchaseHistory
          layout={{
            usingLayout: false,
            title: false,
            filter: false
          }}
          sellType={SELL_TYPE.NTC}
          userID={parseInt(paramsId || '')}
        />
      )
    },
    {
      key: '5',
      label: 'Milestone',
      children: <MileStoneHistory userID={parseInt(paramsId || '')} />
    }
  ]

  useEffect(() => {
    if (paramsId) {
      onFetchUserProfile(parseInt(paramsId))
    }
  }, [paramsId])

  return (
    <>
      <SectionUserInfo
        auth={auth}
        user={user?.data}
        getUserProfile={GetUserProfile}
      />
      <div className='mt-2'>
        <Tabs
          defaultActiveKey='1'
          size='large'
          tabPosition='top'
          destroyInactiveTabPane
        >
          {items.map((element) => {
            return (
              <TabPane tab={element.label} key={element.key}>
                {element.children}
              </TabPane>
            )
          })}
        </Tabs>
      </div>
    </>
  )
}

interface IUserInfoProps {
  auth: IAuthState
  user: IUserState | undefined
  totalNoctuaGold: number
  GetGoldBalance: (userId: number) => void
  GetUserProfile: (id: number) => void
  paramsId: string | undefined
}
