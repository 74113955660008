import { Modal } from 'antd'
import React from 'react'
import SELL_TYPE from '../../../constants/enum/sell-type'
import TransactionType from '../../../components/transaction/detail-transaction'
import IModalProps from '../../../interfaces/models/transaction/modal-transaction'

export default function ModalTransaction({
  open,
  title,
  handleOk,
  handleClose,
  details
}: IModalProps) {
  return (
    <Modal
      visible={open}
      title={title}
      onOk={handleOk}
      onCancel={handleClose}
      footer={null}
    >
      <TransactionType
        reason={details?.sellType == SELL_TYPE.MONEY ? 'topup' : 'exchange'}
        createdAt={details?.createdAt}
        receiptId={details?.receiptID}
        noctuaId={details?.user?.id}
        gameCode={details?.game?.name || details?.gameCode}
        gameIcon={details?.game?.content?.icon}
        goldAmount={
          (details?.sellType == SELL_TYPE.MONEY && details?.goldAmount) ||
          undefined
        }
        usdAmount={
          (details?.sellType == SELL_TYPE.MONEY && details?.totalUSD) ||
          undefined
        }
        amount={details?.sellType == SELL_TYPE.NTC && details?.total}
        status={details?.status}
        email={details?.user?.email}
        notes={
          details?.item?.content?.name +
          ' - ' +
          details?.item?.content?.description
        }
        callbackQsdk={details?.callbackQsdk}
      />
    </Modal>
  )
}
