import { Button, Modal, Row, Table } from 'antd'
import React from 'react'
import MetadataColumn from '../../../constants/table/metadata'
import IMetadata from '../../../interfaces/models/metadata'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import IMetadataState from '../../../interfaces/states/metadata'
import ButtonAction from '../../../components/button-action'
import IAuthState from '../../../interfaces/states/auth'
import USER_ACTION from '../../../constants/user-action'

interface IProps {
  metadata: IMetadataState
  onClickEdit: (record: IMetadata) => void
  auth: IAuthState
}

const MetadataList = ({ metadata, onClickEdit, auth }: IProps) => {
  return (
    <Table
      loading={metadata?.requesting}
      columns={[
        ...MetadataColumn,
        {
          title: 'Action',
          key: 'action',
          width: 250,
          render: (record: IMetadata) => (
            <Row>
              <ButtonAction
                level={auth.ruleActivePage.level}
                action={USER_ACTION.UPDATE}
                icon={<EditOutlined />}
                className='mr-1'
                onClick={() => onClickEdit(record)}
              >
                Edit
              </ButtonAction>
            </Row>
          )
        }
      ]}
      dataSource={metadata?.list || []}
      rowKey={(wr: IMetadata) => wr.id}
      pagination={{
        hideOnSinglePage: true,
        showLessItems: true,
        pageSize: metadata?.paging?.limit || 0,
        total: metadata?.paging?.totalData
      }}
    />
  )
}

export default MetadataList
