import { DatePicker, DatePickerProps } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment'
import React from 'react'
export default function InputDatePicker({
  startDate,
  endDate,
  onChangeStartDate,
  onChangeEndDate
}: IPropsDatePicker) {
  const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string
  ) => {
    if (value == null) {
      onChangeStartDate(0)
      onChangeEndDate(0)
    } else {
      onChangeStartDate(new Date(dateString[0]))
      onChangeEndDate(new Date(dateString[1]))
    }
  }

  return (
    <DatePicker.RangePicker
      onChange={onChange}
      format='YYYY-MM-DD'
      size='middle'
      value={[
        startDate && moment(startDate, 'DD/MM/YYYY'),
        endDate && moment(endDate, 'DD/MM/YYYY')
      ]}
      style={{ width: '200px' }}
    />
  )
}

interface IPropsDatePicker {
  startDate?: any
  endDate?: any
  onChangeStartDate: (value: any) => void
  onChangeEndDate: (value: any) => void
}
