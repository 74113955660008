import SELL_TYPE from '../../../constants/enum/sell-type'
import { query_params } from '../../../utils/services'
import Base from '../base'

const baseUrl = '/shop/v1'
const pathUrl = 'admin/purchase-history'

export const GetListPurchaseHistory = (
  page: number,
  limit: number,
  search?: string,
  gameCodes?: string,
  statuses?: string,
  createdAtFrom?: number,
  createdAtTo?: number,
  sellType?: SELL_TYPE,
  userID?: number
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    statuses,
    gameCodes,
    createdAtFrom,
    createdAtTo,
    sellType,
    userID
  })

  return Base(baseUrl).get(`${pathUrl}?${params.toString()}`)
}

export const GetDetailPurchaseHistory = (id: number): Promise<any> => {
  return Base(baseUrl).get(`${pathUrl}/${id}`)
}
