import { Modal } from 'antd'
import React from 'react'
import TransactionType from '../../../../components/transaction/detail-transaction'
import IModalProps from '../../../../interfaces/models/transaction/modal-transaction'
import { onFormatGameCodeToGame } from '../../../../utils/formatting'

export default function ModalTransaction({
  open,
  title,
  handleOk,
  handleClose,
  details
}: IModalProps) {
  return (
    <Modal visible={open} title={title} onOk={handleOk} onCancel={handleClose}>
      <TransactionType
        createdAt={details?.createdAt || ''}
        reason={details?.reason}
        receiptId={details?.transactionId}
        noctuaId={details?.user?.id}
        gameCode={onFormatGameCodeToGame(details?.gameCode)}
        amount={details?.amount}
        status={details?.detail?.status}
        email={details?.user?.email}
        notes={
          (details?.note
            ? details?.note
            : (details?.reason == 'topup' &&
                details?.detail?.content?.item?.name) ||
              (details?.reason == 'gacha' &&
                details?.detail?.itemRewardDetail?.content?.name)) || ''
        }
      />
    </Modal>
  )
}
