import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IMilestoneHistoryState } from '../../../interfaces/states/transaction'
import { IRequestList } from '../../../interfaces/request/transaction'

export const GetListMilestoneHistory =
  ({
    page,
    limit,
    search,
    gameCodes,
    statuses,
    createdAtFrom,
    createdAtTo,
    userID
  }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Shop.GetListMilestoneHistory(
        page,
        limit,
        search,
        gameCodes,
        statuses,
        createdAtFrom,
        createdAtTo,
        userID
      ),
      () => {
        dispatch(action(ActionTypes.TransactionLog.ADMIN_MILESTONE_REQUEST))
      },
      (res: IResult<Array<IMilestoneHistoryState>>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_MILESTONE_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_MILESTONE_FAILED, {
            error
          })
        )
    )
  }
