/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MainLayout from '../../components/layout'
import Actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import IUserState from '../../interfaces/states/user'

import UserListPage from './user-list'
import UserInfo from './detail'
import { IGoldLogState } from '../../interfaces/states/transaction'
import IAuthState from '../../interfaces/states/auth'

interface IProps {
  GetUsers: (page?: number, limit?: number, search?: string) => void
  GetUserProfile: (id: number) => void
  GetGoldBalance: (userId: number) => void
  user: IUserState
  goldLog: IGoldLogState
  auth: IAuthState
}

const UserPage = (props: IProps) => {
  const { GetUsers, GetUserProfile, user, GetGoldBalance, goldLog, auth } =
    props
  const params: { id: string } = useParams()

  useEffect(() => {
    const userID = Number(params.id)
    if (!isNaN(userID)) GetGoldBalance(Number(params.id))
  }, [])

  return (
    <MainLayout header={{ title: 'Users' }} breadcrumbs={[{ label: 'Users' }]}>
      {params?.id ? (
        <UserInfo
          auth={auth}
          user={user}
          totalNoctuaGold={goldLog.data?.amount || 0}
          GetGoldBalance={GetGoldBalance}
          GetUserProfile={GetUserProfile}
          paramsId={params?.id}
        />
      ) : (
        <UserListPage auth={auth} user={user} GetUsers={GetUsers} />
      )}
    </MainLayout>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  goldLog: state.goldLog,
  auth: state.auth
})

const mapDispatchToProps = (dispatch: any) => ({
  GetUsers: (page?: number, limit?: number, search?: string) => {
    dispatch(Actions.User.GetList(page, limit, search))
  },
  GetUserProfile: (id: number) => {
    dispatch(Actions.User.GetUserProfile(id))
  },
  GetGoldBalance: (userId: number) => {
    dispatch(Actions.Account.GetGoldBalance(userId))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(UserPage)
