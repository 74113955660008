import SELL_TYPE from '../../constants/enum/sell-type'

export interface IRequestList {
  page: number
  limit: number
  search?: string
  gameCodes?: string
  statuses?: string
  createdAtFrom?: number
  createdAtTo?: number
  createdAtUntil?: number
  userID?: number
  sellType?: SELL_TYPE.MONEY | SELL_TYPE.NTC
}

export interface ICreateLog {
  amount: number
  reason: string
  transactionId: string
  userId: number
  note: string
}

export interface IParamsLayout {
  filter?: boolean
  usingLayout?: boolean
  title?: boolean
}

export const defaultIParamsLayout = {
  filter: true,
  usingLayout: true,
  title: true
}
