import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IPurchaseHistoryState } from '../../../interfaces/states/transaction'
import { IRequestList } from '../../../interfaces/request/transaction'

export const GetListPurchaseHistory =
  ({
    page,
    limit,
    search,
    gameCodes,
    statuses,
    createdAtFrom,
    createdAtTo,
    sellType,
    userID
  }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Shop.GetListPurchaseHistory(
        page,
        limit,
        search,
        gameCodes,
        statuses,
        createdAtFrom,
        createdAtTo,
        sellType,
        userID
      ),
      () => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_REQUEST)
        )
      },
      (res: IResult<Array<IPurchaseHistoryState>>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailPurchaseHistory =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Shop.GetDetailPurchaseHistory(id),
      () => {
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_DETAIL_REQUEST
          )
        )
      },
      (res: IResult<IPurchaseHistoryState>) => {
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_DETAIL_SUCCESS,
            {
              data: res.data
            }
          )
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_PURCHASE_HISTORY_FAILED, {
            error
          })
        )
    )
  }
