import Text from 'antd/lib/typography/Text'
import { ASSETS } from '../constants/assets'
import React from 'react'

export const getIconByReason = (reason: string) => {
  switch (reason) {
    case 'transfer':
      return ASSETS.TRANSACTION_TRANSFER_ICON
    case 'topup':
      return ASSETS.TRANSACTION_TOP_UP_ICON
    case 'refund':
      return ASSETS.TRANSACTION_REFUND_ICON
    case 'compensation':
      return ASSETS.TRANSACTION_COMPENSATION_ICON
    case 'gacha':
      return ASSETS.TRANSACTION_GACHA_ICON
    case 'event':
      return ASSETS.TRANSACTION_EVENT_ICON
    case 'exchange':
      return ASSETS.TRANSACTION_EXCHANGE_ICON
    case 'reward':
      return ASSETS.TRANSACTION_REWARD_ICON
    default:
      return ASSETS.TRANSACTION_TRANSFER_ICON
  }
}

export const getUsdAmountFromGold = (gold: number) => {
  let n = gold
  if (gold < 0) {
    n = gold * -1
  }

  return n / 100
}

export const AmountWithPlusMinusFormat = (
  gold: number,
  reason: string,
  curr?: string
) => {
  if (gold != 0) {
    return (
      <Text style={gold > 0 ? { color: '#1C9F51' } : { color: '#B52121' }}>
        {gold} {reason == 'gacha' ? 'Ticket' : curr ? curr : 'Gold'}
      </Text>
    )
  }
  return (
    <Text>
      {gold} {reason == 'gacha' ? 'Ticket' : 'Gold'}
    </Text>
  )
}
