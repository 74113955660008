/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import Text from 'antd/lib/typography/Text'
import actions from '../../../../store/actions'
import { ReduxState } from '../../../../store/reducers'
import ModalTransaction from '../modal'
import InputDatePicker from '../../../../components/filter/input-datepicker'
import InputSearchField from '../../../../components/filter/input-search-field'
import TableTransactions from '../../../../components/transaction/tabel-transaction'
import { IRequestList } from '../../../../interfaces/request/transaction'
import {
  IGoldLogState,
  IGoldTransferState
} from '../../../../interfaces/states/transaction'
import { GoldListColumns } from '../../../../constants/table/noctua-gold-transaction/gold-log'
import { columns as GoldTransferColumns } from '../../../../constants/table/gold-transfer'
import ModalTransfer from '../modal/transfer-modal'
import SelectOptions from '../../../../components/filter/select-options'

const GoldHistoryList = (props: IProps) => {
  const initialPage = 1
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [searchField, setSearchField] = React.useState<string>('')
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false)
  const [details, setDetails] = useState<any>()
  const [goldData, setGoldData] = useState<any>()
  const [selectedGameOptions, setSelectedGameOptions] = useState<string[]>([])
  const [selectedStatusOptions, setSelectStatusOptions] = useState<string[]>([])

  const {
    GetList,
    GetListTransfer,
    goldLog,
    goldTransfer,
    userID,
    title,
    filter,
    onlyTransfer
  } = props

  const onFetchList = (page: number) => {
    if (onlyTransfer) {
      GetListTransfer({
        page: page,
        limit: 10,
        search: searchField,
        gameCodes: selectedGameOptions?.join(','),
        statuses: selectedStatusOptions?.join(','),
        createdAtFrom: startDate,
        createdAtTo: endDate
      })
    } else
      GetList({
        page: page,
        limit: 10,
        search: searchField,
        createdAtFrom: startDate,
        createdAtTo: endDate,
        userID: userID
      })
  }

  const onShowingDetailTransaction = (data: any) => {
    setDetails(data)
    setIsShowingDetails(true)
  }

  useEffect(() => {
    onFetchList(initialPage)
  }, [startDate, endDate, selectedGameOptions, selectedStatusOptions])

  useEffect(() => {
    if (goldLog || goldTransfer) {
      setGoldData(onlyTransfer ? goldTransfer : goldLog)
    }
  }, [goldLog, goldTransfer])

  return (
    <>
      <Row className='mb-2' justify='space-between' align='middle'>
        {title && (
          <Col>
            <Text className='text-size-24 text-color-primary-purple'>
              {title}
            </Text>
          </Col>
        )}
        {filter && (
          <Col>
            <Row gutter={16}>
              {onlyTransfer && (
                <Col>
                  <SelectOptions
                    onChange={setSelectedGameOptions}
                    selected={selectedGameOptions}
                    filter='game'
                    placeholder='Select game'
                  />
                </Col>
              )}
              {onlyTransfer && (
                <Col>
                  <SelectOptions
                    onChange={setSelectStatusOptions}
                    selected={selectedStatusOptions}
                    filter='status'
                    placeholder='Select status'
                  />
                </Col>
              )}
              <Col>
                <InputDatePicker
                  onChangeStartDate={setStartDate}
                  onChangeEndDate={setEndDate}
                />
              </Col>
              <Col>
                <InputSearchField
                  placeholder='search data by: Game Code, User ID, Status, Server ID, Account ID, Receipt ID'
                  searchField={searchField}
                  onChange={setSearchField}
                  onPress={() => onFetchList(1)}
                  width='350px'
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {goldData && (
            <TableTransactions
              columns={
                onlyTransfer
                  ? GoldTransferColumns({ onShowingDetailTransaction })
                  : GoldListColumns({ onShowingDetailTransaction })
              }
              requesting={goldData?.requesting}
              dataList={goldData ? goldData.list : []}
              pagination={{
                total: goldData.paging?.totalData,
                currentPage: goldData?.paging?.currentPage
              }}
              onPagination={onFetchList}
            />
          )}
        </Col>
      </Row>
      {isShowingDetails &&
        details &&
        (onlyTransfer ? (
          <ModalTransfer
            open={isShowingDetails}
            handleOk={() => setIsShowingDetails(false)}
            handleClose={() => setIsShowingDetails(false)}
            title='Transaction Detail'
            details={details}
          />
        ) : (
          <ModalTransaction
            open={isShowingDetails}
            handleOk={() => setIsShowingDetails(false)}
            handleClose={() => setIsShowingDetails(false)}
            title='Transaction Detail'
            details={details}
          />
        ))}
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  goldLog: state.goldLog,
  goldTransfer: state.transaction
})

const mapDispatchToProps = (dispatch: any) => ({
  GetList: (props: IRequestList) =>
    dispatch(actions.Account.GetListGoldLog(props)),
  GetListTransfer: (props: IRequestList) =>
    dispatch(actions.Shop.GetListGoldTransfer(props))
})

export default connect(mapStateToProps, mapDispatchToProps)(GoldHistoryList)

interface IProps {
  GetList: (props: IRequestList) => void
  GetListTransfer: (props: IRequestList) => void
  goldLog: IGoldLogState
  goldTransfer: IGoldTransferState
  userID?: number
  title?: string
  filter?: boolean
  onlyTransfer?: boolean
}
