export const ASSETS = {
  PROFILE_ICON: 'profile.png',
  TRANSACTION_TRANSFER_ICON: 'transaction/transfer.png',
  TRANSACTION_TOP_UP_ICON: 'transaction/topup.png',
  TRANSACTION_REFUND_ICON: 'transaction/refund.png',
  TRANSACTION_COMPENSATION_ICON: 'transaction/compensation.png',
  TRANSACTION_GACHA_ICON: 'transaction/gacha.png',
  TRANSACTION_EVENT_ICON: 'transaction/event.png',
  TRANSACTION_EXCHANGE_ICON: 'transaction/exchange.png',
  TRANSACTION_REWARD_ICON: 'transaction/reward.png'
}

export const ASSETS_USERINFO = {
  NOCTUA_GOLD: 'gold.png',
  NOCTUA_POINT: 'point.png',
  GACHA_TICKET: 'ticket.png'
}
