import { useEffect, useState } from 'react'

const useDebounce = (val: any, delay: number) => {
  const [debounceVal, setDebounceVal] = useState(val)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebounceVal(val)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [val, delay]) // Include 'delay' in the dependency array

  return debounceVal
}

export default useDebounce
