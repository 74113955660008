import { Row, Col, Image, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { ASSETS_USERINFO } from '../../../../constants/assets'
import IUser from '../../../../interfaces/models/user'
import ModalSendItem from '../../modal/send-item'
import { useParams } from 'react-router-dom'
import { Item } from '../../../../constants/enum/item'
import IAuthState from '../../../../interfaces/states/auth'
import USER_ACTION from '../../../../constants/user-action'
import { havePermission } from '../../../../utils/permission'

interface IProps {
  auth: IAuthState
  user: IUser | undefined
  getUserProfile: (id: number) => void
}

export default function SectionUserInfo({
  auth,
  user,
  getUserProfile
}: IProps) {
  const [itemType, setItemType] = useState<Item>(Item.noctuaGold)
  const params: { id: string } = useParams()
  const userId = Number(params.id)
  const [isModalSendNGOpen, setIsModalSendNGOpen] = useState<boolean>(false)
  return (
    <>
      <Row align='middle' justify='space-between'>
        <Col>
          <Row gutter={[16, 16]} align='middle' justify='center'>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 5
              }}
            >
              <div className='user-profile'>
                <Image
                  src={user?.configuration?.image}
                  width={62}
                  preview={false}
                  style={{
                    position: 'absolute',
                    bottom: -40,
                    left: 2
                  }}
                />
              </div>
              <Text
                className='text-size-16'
                style={{
                  fontWeight: 'bold'
                }}
              >
                ID: {user?.id}
              </Text>
            </Col>
            <Col>
              <Row align='middle'>
                <Text className='text-size-24' strong>
                  {user?.nickname}
                </Text>
              </Row>
              <Row
                align='middle'
                style={{
                  display: 'flex',
                  gap: 15
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: 3
                  }}
                >
                  <Image
                    src={'/images/' + ASSETS_USERINFO.NOCTUA_GOLD}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <Text className='text-size-16'> {user?.goldAmount || 0}</Text>
                </div>
                <div
                  style={{
                    display: 'flex',
                    gap: 3
                  }}
                >
                  <Image
                    src={'/images/' + ASSETS_USERINFO.GACHA_TICKET}
                    width={20}
                    height={20}
                    preview={false}
                  />
                  <Text className='text-size-16'>
                    {' '}
                    {user?.gachaTicket || 0}
                  </Text>
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
        {havePermission(auth.ruleActivePage.level, USER_ACTION.CREATE) && (
          <Col>
            <Row>
              <Text className='text-size-20'>Send {user?.nickname}</Text>
            </Row>

            <Row gutter={[8, 8]}>
              <Col>
                <Button
                  type='primary'
                  style={{
                    borderRadius: '8px',
                    height: '48px'
                  }}
                  onClick={() => {
                    setIsModalSendNGOpen(true)
                    setItemType(Item.noctuaGold)
                  }}
                >
                  {/* <a href={`/send-noctua-gold/${user?.id}`}> */}
                  <Row align='middle'>
                    <Image
                      src={'/images/' + ASSETS_USERINFO.NOCTUA_GOLD}
                      width={40}
                      height={40}
                      preview={false}
                    />
                    Noctua Gold
                  </Row>
                  {/* </a> */}
                </Button>
              </Col>
              {/* <Col>
            <Button
              type='primary'
              style={{
                borderRadius: '8px',
                height: '48px'
              }}
            >
              <a href={`/send-noctua-point/${user?.id}`}>
                <Row align='middle'>
                  <Image
                    src={'/images/' + ASSETS_USERINFO.NOCTUA_POINT}
                    width={40}
                    height={40}
                    preview={false}
                  />
                  Noctua Point
                </Row>
              </a>
            </Button>
          </Col> */}
              <Col>
                <Button
                  type='primary'
                  style={{
                    borderRadius: '8px',
                    height: '48px'
                  }}
                  onClick={() => {
                    setIsModalSendNGOpen(true)
                    setItemType(Item.ticket)
                  }}
                >
                  <Row align='middle'>
                    <Image
                      src={'/images/' + ASSETS_USERINFO.GACHA_TICKET}
                      width={40}
                      height={40}
                      preview={false}
                    />
                    Gacha Ticket
                  </Row>
                </Button>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <ModalSendItem
        isOpen={isModalSendNGOpen}
        setIsOpen={setIsModalSendNGOpen}
        itemType={itemType}
        callback={() => !isNaN(userId) && getUserProfile(userId)}
      />
    </>
  )
}
