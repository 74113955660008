import React from 'react'
import { Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import { onFormatGameCodeToGame } from '../../utils/formatting'
import Tag from '../../components/tag'
import { convertUnixTimestampToDate } from '../../utils/date'

export const MilestoneListColumns = ({ onShowingDetailTransaction }: any) => {
  return [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 150
    },
    {
      title: 'Receipt ID',
      dataIndex: 'receiptID',
      key: 'receiptID',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 150
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (
        <Text className='text-size-12'>{convertUnixTimestampToDate(text)}</Text>
      ),
      width: 150
    },
    {
      title: 'Game',
      dataIndex: 'gameCode',
      key: 'gameCode',
      render: (value) => (
        <Text className='text-size-12'>
          {onFormatGameCodeToGame(value || '-')}
        </Text>
      ),
      width: 120
    },
    {
      title: 'Milestone',
      dataIndex: 'milestoneCode',
      key: 'milestoneCode',
      render: (value) => <Text className='text-size-12'>{value || '-'}</Text>,
      width: 120
    },
    {
      title: `Account ID / Server ID`,
      dataIndex: 'detail',
      key: 'detail',
      render: (text, value) => (
        <Text className='text-size-12'>
          {value?.accountId || '-'} / {value?.serverId || '-'}
        </Text>
      ),
      width: 120
    },
    {
      title: 'Item ID',
      dataIndex: 'itemId',
      key: 'itemId',
      render: (text) => <Text className='text-size-12'>{text || '-'}</Text>,
      width: 120
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      key: 'itemName',
      render: (text) => <Text className='text-size-12'>{text || '-'}</Text>,
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag status={text} />,
      width: 100
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, value) => (
        <Button
          onClick={() => onShowingDetailTransaction(value)}
          type='link'
          className='text-size-12'
        >
          See Details
        </Button>
      ),
      width: 100
    }
  ]
}
