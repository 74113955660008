import { GetListGoldTransfer, GetDetailGoldTransfer } from './gold-transfer'
import { GetListMilestoneHistory } from './milestone'
import { GetDetailPurchaseHistory, GetListPurchaseHistory } from './purchase'
export default {
  GetListGoldTransfer,
  GetDetailGoldTransfer,
  GetListMilestoneHistory,
  GetListPurchaseHistory,
  GetDetailPurchaseHistory
}
