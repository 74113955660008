import { Modal } from 'antd'
import React from 'react'
import TransactionDetail from '../../../components/transaction/detail-transaction'
import IModalProps from '../../../interfaces/models/transaction/modal-transaction'

export default function ModalTransaction({
  open,
  title,
  handleOk,
  handleClose,
  details
}: IModalProps) {
  return (
    <Modal
      visible={open}
      title={title}
      onOk={handleOk}
      onCancel={handleClose}
      footer={null}
    >
      <TransactionDetail
        date={details?.createdAt}
        accountId={details?.accountId}
        receiptId={details?.receiptID}
        serverId={details?.serverId}
        gameCode={details?.gameCode}
        status={details?.status}
        notes={details?.itemName + ' (Item ID:' + details?.itemId + ') '}
      />
    </Modal>
  )
}
