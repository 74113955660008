import { Button, Col, Image, Row } from 'antd'
import {
  getIconByReason,
  getUsdAmountFromGold
} from '../../utils/table-formatting'
import Text from 'antd/lib/typography/Text'
import { convertUnixTimestampToDate } from '../../utils/date'
import Tag from '../../components/tag'
import React from 'react'

export const columns = ({ onShowingDetailTransaction }) => [
  {
    title: 'Transaction Type',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text) => (
      <Row gutter={8}>
        <Col>
          <Image src={'/images/' + getIconByReason('transfer')} width={40} />
        </Col>
        <Col>
          <Row>
            <Text className='text-size-12 font-semibold'>Transfer</Text>
          </Row>
          <Row>
            <Text className='text-size-12'>
              {convertUnixTimestampToDate(text)}
            </Text>
          </Row>
        </Col>
      </Row>
    ),
    width: 200
  },
  {
    title: 'Receipt ID',
    dataIndex: 'receiptId',
    key: 'receiptId',
    render: (text) => <Text className='text-size-12'>{text}</Text>,
    width: 150
  },
  {
    title: 'Email / Noctua ID',
    dataIndex: 'user',
    key: 'user',
    render: (text, value) => (
      <Text className='text-size-12'>
        {text ? text?.email : '-'}/{value.accountId}
      </Text>
    ),
    width: 100
  },
  {
    title: `Server ID / Game ID`,
    dataIndex: 'serverId',
    key: 'serverId',
    render: (text: any, value: any) => (
      <Text className='text-size-12'>
        {text} / {value?.game?.id}
      </Text>
    ),
    width: 100
  },
  {
    title: 'Game',
    dataIndex: 'game',
    key: 'game',
    render: (text) => <Text className='text-size-12'>{text?.name}</Text>,
    width: 100
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: (text) => (
      <>
        <div>
          <Text className='text-size-12' strong>
            {text} Gold
          </Text>
        </div>
        <div>
          <Text className='text-size-12'>USD {getUsdAmountFromGold(text)}</Text>
        </div>
      </>
    ),
    width: 100
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <Tag status={text} />,
    width: 100
  },
  {
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: (text, value) => (
      <Button
        onClick={() => onShowingDetailTransaction(value)}
        type='link'
        className='text-size-12'
      >
        See Details
      </Button>
    ),
    width: 100
  }
]
