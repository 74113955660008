import { v4 as uuidv4 } from 'uuid'
import { onCapitalize } from './formatting'

export const generateUniqueString = () => {
  const uniqueString = uuidv4()
  return uniqueString
}

export const generateUuidWithTimestamp = (timestamp: string) => {
  const uuid = generateUniqueString()
  return timestamp.slice(-5) + '-' + uuid.slice(-5)
}

export const generateTransactionID = (timestamp: string, reason: string) => {
  const uuidWithTs = generateUuidWithTimestamp(timestamp)
  return onCapitalize(reason.slice(0, 4)) + '-' + uuidWithTs
}
