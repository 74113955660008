import { Image, Row, Col, Divider } from 'antd'
import Text from 'antd/lib/typography/Text'
import React from 'react'
import { convertUnixTimestampToDate } from '../../../utils/date'
import { onCapitalize } from '../../../utils/formatting'
import {
  getIconByReason,
  getUsdAmountFromGold
} from '../../../utils/table-formatting'
import Tag from '../../tag'
export default function TransactionDetails({
  createdAt,
  reason,
  email,
  date,
  noctuaId,
  receiptId,
  goldAmount,
  pointAmount,
  amount,
  gameIcon,
  gameCode,
  serverId,
  accountId,
  status,
  notes,
  callbackQsdk
}: IProps) {
  return (
    <>
      {(createdAt || reason) && (
        <Row
          className='p-2'
          align='middle'
          justify='space-between'
          style={{ background: '#F7F4FE' }}
        >
          <Col>
            <Row align='middle' gutter={6}>
              <Col>
                <Row align='middle'>
                  <Image
                    src={'/images/' + getIconByReason(reason || '')}
                    width={40}
                    preview={false}
                  />
                </Row>
              </Col>
              <Col>
                <Row>
                  <Text className='text-color-second-dark-purple text-size-12'>
                    Transaction Type
                  </Text>
                </Row>
                <Row>
                  <Text strong className='text-size-16'>
                    {onCapitalize(reason || '')}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              {convertUnixTimestampToDate(createdAt || 0)}
            </Text>
          </Col>
        </Row>
      )}
      {date && (
        <Row gutter={16} className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-size-12 text-color-second-dark-purple'>
              Date:{' '}
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12'>
              {convertUnixTimestampToDate(date || 0)}
            </Text>
          </Col>
        </Row>
      )}
      {email && (
        <Row gutter={16} className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-size-12 text-color-second-dark-purple'>
              Email:{' '}
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12'>{email}</Text>
          </Col>
        </Row>
      )}
      {noctuaId && (
        <Row gutter={16} className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-size-12 text-color-second-dark-purple'>
              Noctua ID:{' '}
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12'>{noctuaId}</Text>
          </Col>
        </Row>
      )}
      {receiptId && (
        <Row gutter={16} className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-size-12 text-color-second-dark-purple'>
              Receipt ID:{' '}
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12'>{receiptId}</Text>
          </Col>
        </Row>
      )}
      <Divider />
      {amount && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Amount
            </Text>
          </Col>
          <Col>
            {amount && (
              <Row>
                <Text strong className='text-size-12'>
                  {amount || 0} {reason == 'gacha' ? 'Ticket' : ''}
                </Text>
              </Row>
            )}
          </Col>
        </Row>
      )}
      {goldAmount && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Noctua Gold Amount
            </Text>
          </Col>
          <Col>
            {goldAmount && (
              <>
                <Row>
                  <Text strong className='text-size-12'>
                    {goldAmount || 0} Gold
                  </Text>
                </Row>
                <Row>
                  <Text className='text-size-12'>
                    (USD {getUsdAmountFromGold(goldAmount) || 0})
                  </Text>
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
      {pointAmount && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Noctua Point Amount
            </Text>
          </Col>
          <Col>
            {pointAmount && (
              <>
                <Row>
                  <Text strong className='text-size-12'>
                    {pointAmount || 0} pts
                  </Text>
                </Row>
              </>
            )}
          </Col>
        </Row>
      )}
      {gameCode && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Game:
            </Text>
          </Col>
          <Col>
            <Row align='middle' gutter={[8, 8]}>
              {gameIcon && (
                <Col>
                  <Image
                    preview={false}
                    src={gameIcon}
                    width={24}
                    height={24}
                  />
                </Col>
              )}
              <Col>
                <Text className='text-size-12 font-500'>{gameCode}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {serverId && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Server ID:
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12 font-500'>{serverId}</Text>
          </Col>
        </Row>
      )}
      {accountId && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Game ID:
            </Text>
          </Col>
          <Col>
            <Text className='text-size-12 font-500'>
              {onCapitalize(accountId || '')}
            </Text>
          </Col>
        </Row>
      )}
      {status && (
        <Row className='mt-1' justify='space-between'>
          <Col>
            <Text className='text-color-second-dark-purple text-size-12'>
              Status:
            </Text>
          </Col>
          <Col>
            <Tag status={status} className='mr-0' />
          </Col>
        </Row>
      )}
      <Row className='mt-1' justify='space-between'>
        <Col>
          <Text className='text-color-second-dark-purple text-size-12'>
            Notes:
          </Text>
        </Col>
        <Col>
          <Text className='text-size-12'>{notes || ''}</Text>
        </Col>
      </Row>
      <Row className='mt-1' justify='space-between'>
        <Col>
          <Text className='text-color-second-dark-purple text-size-12'>
            Callback:
          </Text>
        </Col>
        <Col>
          <Text className='text-size-12'>{callbackQsdk || ''}</Text>
        </Col>
      </Row>
    </>
  )
}

interface IProps {
  createdAt?: number
  reason?: string
  email?: string
  date?: number
  noctuaId?: string
  receiptId?: string
  goldAmount?: number
  pointAmount?: number
  usdAmount?: number
  amount?: number
  gameIcon?: string
  gameCode?: string
  serverId?: string
  accountId?: string
  status?: string
  notes?: string
  callbackQsdk?: string
}
