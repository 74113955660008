/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import Text from 'antd/lib/typography/Text'
import actions from '../../../store/actions'
import { ReduxState } from '../../../store/reducers'
import { ICoinLogState } from '../../../interfaces/states/transaction'
import ModalTransaction from '../modal'
import InputDatePicker from '../../../components/filter/input-datepicker'
import InputSearchField from '../../../components/filter/input-search-field'
import { PointListColumns } from '../../../constants/table/point-log'
import TableTransactions from '../../../components/transaction/tabel-transaction'
import { IRequestList } from '../../../interfaces/request/transaction'
import { convertDateToUnixTimestamp } from '../../../utils/date'
const PointList = (props: IProps) => {
  const initialPage = 1
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()

  const [searchField, setSearchField] = React.useState<string>('')
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false)

  const { GetListCoinLog, GetDetailCoinLog, coinLog, userID, title, filter } =
    props

  const onFetchCoinTransactionList = (page: number) => {
    const paramsList = {
      page: page,
      limit: 10,
      search: searchField,
      createdAtFrom: startDate && convertDateToUnixTimestamp(startDate),
      createdAtUntil: endDate && convertDateToUnixTimestamp(endDate),
      userID: userID
    }

    GetListCoinLog(paramsList)
  }

  const onShowingDetailTransaction = (data: any) => {
    GetDetailCoinLog(data?.transactionId)
    setIsShowingDetails(true)
  }

  useEffect(() => {
    onFetchCoinTransactionList(initialPage)
  }, [startDate, endDate])

  return (
    <>
      <Row className='mb-2' justify='space-between' align='middle'>
        {title && (
          <Col>
            <Text className='text-size-24 text-color-primary-purple' strong>
              {title}
            </Text>
          </Col>
        )}
        {filter && (
          <Col>
            <Row gutter={16}>
              <Col>
                <InputDatePicker
                  startDate={startDate}
                  endDate={endDate}
                  onChangeStartDate={setStartDate}
                  onChangeEndDate={setEndDate}
                />
              </Col>
              <Col>
                <InputSearchField
                  width={'350px'}
                  placeholder={
                    'search data by: User ID, Receipt ID, Type, Amount'
                  }
                  searchField={searchField}
                  onChange={setSearchField}
                  onPress={() => onFetchCoinTransactionList(initialPage)}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {coinLog && (
            <TableTransactions
              columns={PointListColumns({ onShowingDetailTransaction })}
              requesting={coinLog?.requesting}
              dataList={coinLog ? coinLog.list : []}
              pagination={{
                total: coinLog.paging?.totalData,
                currentPage: coinLog?.paging?.currentPage
              }}
              onPagination={onFetchCoinTransactionList}
            />
          )}
        </Col>
      </Row>
      {isShowingDetails && coinLog && !coinLog.requesting && (
        <ModalTransaction
          open={isShowingDetails}
          handleOk={() => setIsShowingDetails(false)}
          handleClose={() => setIsShowingDetails(false)}
          title='Transaction Detail'
          details={coinLog?.data}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  coinLog: state.coinLog
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListCoinLog: (props: IRequestList) =>
    dispatch(actions.Account.GetListCoinLog(props)),
  GetDetailCoinLog: (id: number) =>
    dispatch(actions.Account.GetDetailCoinLog(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PointList)

interface IProps {
  GetListCoinLog: (props: IRequestList) => void
  GetDetailCoinLog: (id: number) => void
  coinLog: ICoinLogState
  userID?: number
  title?: string
  filter?: boolean
}
