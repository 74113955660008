import React from 'react'
import { Button, Col, Image, Row } from 'antd'
import Text from 'antd/lib/typography/Text'
import { onFormatGameCodeToGame } from '../../utils/formatting'
import Tag from '../../components/tag'
import { convertUnixTimestampToDate } from '../../utils/date'
import SELL_TYPE from '../enum/sell-type'
import {
  AmountWithPlusMinusFormat,
  getIconByReason
} from '../../utils/table-formatting'

export const purchaseHistoryColumns = ({ onShowingDetailTransaction }: any) => {
  return [
    {
      title: 'Transaction Type',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, value) => (
        <Row gutter={8}>
          <Col>
            {value?.sellType == SELL_TYPE.MONEY ? (
              <Image src={'/images/' + getIconByReason('topup')} width={40} />
            ) : (
              <Image
                src={'/images/' + getIconByReason('exchange')}
                width={40}
              />
            )}
          </Col>
          <Col>
            <Row>
              <Text className='text-size-12 font-semibold'>
                {value?.sellType == SELL_TYPE.MONEY ? 'Top up' : 'Exchange  '}
              </Text>
            </Row>
            <Row>
              <Text className='text-size-12'>
                {convertUnixTimestampToDate(text)}
              </Text>
            </Row>
          </Col>
        </Row>
      ),
      width: 250
    },
    {
      title: 'Receipt ID',
      dataIndex: 'receiptID',
      key: 'receiptID',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 150
    },
    {
      title: 'Email / Noctua ID',
      dataIndex: 'user',
      key: 'user',
      render: (text, value) => (
        <Text className='text-size-12'>
          {text ? text?.email : '-'}/{value.accountId}
        </Text>
      ),
      width: 100
    },
    {
      title: `Server ID / Game ID`,
      dataIndex: 'serverId',
      key: 'serverId',
      render: (text: any, value: any) => (
        <Text className='text-size-12'>
          {text} / {value?.game?.id}
        </Text>
      ),
      width: 120
    },
    {
      title: 'Game',
      dataIndex: 'gameCode',
      key: 'gameCode',
      render: (text) => (
        <Text className='text-size-12'>{onFormatGameCodeToGame(text)}</Text>
      ),
      width: 120
    },
    {
      title: 'Amount',
      dataIndex: 'goldAmount',
      key: 'goldAmount',
      render: (text, value) =>
        value?.sellType == SELL_TYPE.MONEY ? (
          <>
            <div>
              <Text className='text-size-12' strong>
                {AmountWithPlusMinusFormat(text, value?.reason, 'Gold')}
              </Text>
            </div>
            <div>
              <Text className='text-size-12'>USD {value.totalUSD}</Text>
            </div>
          </>
        ) : (
          <div>
            <Text className='text-size-12' strong>
              {AmountWithPlusMinusFormat(value.total, value?.reason, ' ')}
            </Text>
          </div>
        ),
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => <Tag status={text} />,
      width: 80
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, value) => (
        <Button
          onClick={() => onShowingDetailTransaction(value)}
          type='link'
          size='small'
          className='text-size-12'
        >
          See Details
        </Button>
      ),
      width: 80
    }
  ]
}
