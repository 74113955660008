/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import ModalTransaction from './modal'
import React from 'react'
import InputDatePicker from '../../../components/filter/input-datepicker'
import InputSearchField from '../../../components/filter/input-search-field'
import SelectOptions from '../../../components/filter/select-options'
import { GachaTokenColumns } from '../../../constants/table/gacha-token-log'
import TableTransactions from '../../../components/transaction/tabel-transaction'
import {
  defaultIParamsLayout,
  IParamsLayout,
  IRequestList
} from '../../../interfaces/request/transaction'
import { ReduxState } from '../../../store/reducers'
import actions from '../../../store/actions'
import { connect } from 'react-redux'
import { IGachaTokenLogState } from '../../../interfaces/states/transaction'
import Layout from '../../../components/layout'

const Gacha = (props: IProps) => {
  const {
    gachaHistory,
    GetListGachaTokenLog,
    GetDetailGachaTokenLog,
    userID,
    layout = defaultIParamsLayout
  } = props
  const initialPage = 1
  const [searchField, setSearchField] = useState<string>('')

  const [startDate, setStartDate] = useState<number>(0)
  const [endDate, setEndDate] = useState<number>(0)
  const [selectedGameOptions, setSelectGameOptions] = useState<string[]>([])

  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false)

  const onFetchGacha = (page: number) => {
    const paramsList = {
      page: page,
      limit: 10,
      search: searchField,
      gameCodes: selectedGameOptions?.join(','),
      createdAtFrom: startDate,
      createdAtTo: endDate,
      userID: userID
    }
    GetListGachaTokenLog(paramsList)
  }

  const onShowingDetailTransaction = (data: any) => {
    setIsShowingDetails(true)
    GetDetailGachaTokenLog(data?.transactionId)
  }

  useEffect(() => {
    onFetchGacha(initialPage)
  }, [selectedGameOptions, startDate, endDate])

  const Filter = () => {
    return (
      <Row align='middle' justify='end' gutter={[16, 16]} className='mb-2'>
        <Col>
          <SelectOptions
            onChange={setSelectGameOptions}
            selected={selectedGameOptions}
            filter='game'
            placeholder='Select game'
          />
        </Col>
        <Col>
          <InputDatePicker
            onChangeStartDate={setStartDate}
            onChangeEndDate={setEndDate}
          />
        </Col>
        <Col>
          <InputSearchField
            width='350px'
            placeholder='search data by: User ID, Reason, Amount, Gacha Code, Game Code, Receipt ID'
            searchField={searchField}
            onChange={setSearchField}
            onPress={() => onFetchGacha(initialPage)}
          />
        </Col>
      </Row>
    )
  }

  const View = () => {
    return (
      <>
        {layout?.filter && <Filter />}
        <Row>
          <Col span={24}>
            {gachaHistory && (
              <TableTransactions
                columns={GachaTokenColumns({ onShowingDetailTransaction })}
                requesting={gachaHistory?.requesting}
                dataList={gachaHistory ? gachaHistory.list : []}
                pagination={{
                  total: gachaHistory.paging?.totalData,
                  currentPage: gachaHistory?.paging?.currentPage
                }}
                onPagination={onFetchGacha}
              />
            )}
          </Col>
        </Row>
        <Row>
          {isShowingDetails &&
            gachaHistory?.data &&
            !gachaHistory?.requesting && (
              <ModalTransaction
                open={isShowingDetails}
                title={'Purchase Details'}
                handleClose={() => setIsShowingDetails(false)}
                handleOk={() => setIsShowingDetails(false)}
                details={gachaHistory?.data}
              />
            )}
        </Row>
      </>
    )
  }

  return layout?.usingLayout ? (
    <Layout>
      <View />
    </Layout>
  ) : (
    <View />
  )
}

const mapStateToProps = (state: ReduxState) => ({
  gachaHistory: state.gachaTokenLog
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListGachaTokenLog: (props: IRequestList) =>
    dispatch(actions.Account.GetListGachaTokenLog(props)),
  GetDetailGachaTokenLog: (id: number) =>
    dispatch(actions.Account.GetDetailGachaTokenLog(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Gacha)

interface IProps {
  GetListGachaTokenLog: (props: IRequestList) => void
  GetDetailGachaTokenLog: (id: number) => void
  gachaHistory: IGachaTokenLogState
  layout?: IParamsLayout
  userID?: number
}
