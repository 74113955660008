import { GetListCoinLog, GetDetailCoinLog, CreateCoinLog } from './coin-log'
import {
  GetListGoldLog,
  GetDetailGoldLog,
  CreateGoldLog,
  GetGoldBalance,
  CreateBulkGoldLogs
} from './gold-log'
import {
  GetListGachaTokenLog,
  GetDetailGachaTokenLog,
  CreateGachaLog,
  CreateBulkGachaLogs
} from './gacha-token-log'

import {
  GetDetailGame,
  GetListGame,
  CreateGame,
  DeleteGame,
  EditGame
} from './game'

import {
  GetDetailNews,
  GetListNews,
  CreateNews,
  DeleteNews,
  EditNews
} from './news'
import Banner from './banner'

export default {
  GetListCoinLog,
  GetDetailCoinLog,
  CreateCoinLog,
  GetListGoldLog,
  GetDetailGoldLog,
  CreateGoldLog,
  CreateBulkGoldLogs,
  GetGoldBalance,
  GetListGachaTokenLog,
  GetDetailGachaTokenLog,
  CreateGachaLog,
  CreateBulkGachaLogs,
  Banner,
  GetDetailGame,
  GetListGame,
  CreateGame,
  DeleteGame,
  EditGame,
  GetDetailNews,
  GetListNews,
  CreateNews,
  DeleteNews,
  EditNews
}
