import React, { useState } from 'react'
import { ISendItem } from './type'
import { Button } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

interface IProps {
  item: ISendItem
  handleCancelItem: () => void
  handleSaveItem: (note: string) => void
}

export default function SendItemNote({
  item,
  handleCancelItem,
  handleSaveItem
}: IProps) {
  const [note, setNote] = useState<string>(item.note || '')
  return (
    <div
      className='flex flex-vertical'
      style={{
        gap: 10
      }}
    >
      <div
        className='flex'
        style={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <div className='flex flex-vertical'>
          <span>
            <strong>Noctua ID:</strong> {item.user?.id}
          </span>
          <span>
            <strong>Email:</strong> {item.user.email}
          </span>
        </div>
        <div className='flex' style={{ gap: 10 }}>
          <Button type='ghost' onClick={handleCancelItem}>
            Back
          </Button>
          <Button type='primary' onClick={() => handleSaveItem(note)}>
            Save Note
          </Button>
        </div>
      </div>
      <TextArea
        rows={5}
        showCount
        maxLength={100}
        placeholder='Add note here'
        value={note}
        onChange={(e) => setNote(e.target.value)}
        style={{ resize: 'none', borderRadius: '5px' }}
      />
    </div>
  )
}
