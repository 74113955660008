import { IResult } from '@attn/general/dist/interfaces/api'
import { AnyAction, Dispatch } from 'redux'
import { action } from 'typesafe-actions'

import ActionTypes from '../../../constants/action-types'
import Api from '../../apis'
import Base from '../base'
import { IGoldTransferState } from '../../../interfaces/states/transaction'
import { IRequestList } from '../../../interfaces/request/transaction'

export const GetListGoldTransfer =
  ({
    page,
    limit,
    search,
    gameCodes,
    statuses,
    createdAtFrom,
    createdAtTo
  }: IRequestList) =>
  (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Shop.GetListGoldTransfer(
        page,
        limit,
        search,
        gameCodes,
        statuses,
        createdAtFrom,
        createdAtTo
      ),
      () => {
        dispatch(action(ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_REQUEST))
      },
      (res: IResult<Array<IGoldTransferState>>) => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_SUCCESS, {
            list: res.data,
            paging: res.paging
          })
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_FAILED, {
            error
          })
        )
    )
  }

export const GetDetailGoldTransfer =
  (id: number) => (dispatch: Dispatch<AnyAction>) => {
    return Base.CallAction(
      Api.Shop.GetDetailGoldTransfer(id),
      () => {
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_REQUEST)
        )
      },
      (res: IResult<IGoldTransferState>) => {
        dispatch(
          action(
            ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_SUCCESS,
            {
              data: res.data
            }
          )
        )
      },
      (error: string) =>
        dispatch(
          action(ActionTypes.TransactionLog.ADMIN_GOLD_TRANSFER_DETAIL_FAILED, {
            error
          })
        )
    )
  }
