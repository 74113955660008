import { query_params } from '../../../utils/services'
import Base from '../base'

const baseUrl = '/shop/v1'
const pathUrl = 'admin/milestone-history'

export const GetListMilestoneHistory = (
  page: number,
  limit: number,
  search?: string,
  gameCodes?: string,
  statuses?: string,
  createdAtFrom?: number,
  createdAtTo?: number,
  userID?: number
): Promise<any> => {
  const params = query_params({
    page,
    limit,
    search,
    statuses,
    gameCodes,
    createdAtFrom,
    createdAtTo,
    userID
  })

  return Base(baseUrl).get(`${pathUrl}?${params}`)
}
