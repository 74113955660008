import React from 'react'
import { Table } from 'antd'

import UserTableColumns from '../../../constants/table/user'
import IUserState from '../../../interfaces/states/user'
import IUser from '../../../interfaces/models/user'

interface IProps {
  user: IUserState
  onChangePage: (pn: number) => void
}

const UsersList = (props: IProps) => {
  const { user, onChangePage } = props

  return (
    <Table
      style={{ width: '100%' }}
      columns={UserTableColumns}
      dataSource={(user.list as IUser[]) || []}
      loading={user?.requesting}
      rowKey={(user: IUser) => user.id}
      pagination={{
        hideOnSinglePage: true,
        showSizeChanger: false,
        pageSize: user?.paging?.limit,
        showLessItems: true,
        current: user?.paging?.currentPage,
        total: user?.paging?.totalData,
        onChange: onChangePage
      }}
    />
  )
}

export default UsersList
