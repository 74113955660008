import { Modal } from 'antd'
import React from 'react'
import TransactionDetail from '../../../../components/transaction/detail-transaction'
import IModalProps from '../../../../interfaces/models/transaction/modal-transaction'

export default function ModalTransfer({
  open,
  title,
  handleOk,
  handleClose,
  details
}: IModalProps) {
  return (
    <Modal
      visible={open}
      title={title}
      onOk={handleOk}
      onCancel={handleClose}
      closeIcon={null}
      footer={null}
    >
      <TransactionDetail
        createdAt={details?.createdAt || ''}
        reason={'Transfer'}
        email={details?.user?.email || ''}
        noctuaId={details?.userId || ''}
        receiptId={details?.receiptId || ''}
        gameIcon={details?.game?.content?.icon || ''}
        accountId={details?.accountId}
        gameCode={details?.game?.name}
        serverId={details?.serverId}
        goldAmount={details?.detail?.goldAmount}
        usdAmount={details?.detail?.usdAmount}
        status={details?.status}
        notes={details?.note}
        callbackQsdk={details?.callbackQsdk}
      />
    </Modal>
  )
}
