import { Modal } from 'antd'
import React from 'react'
import { onCapitalize } from '../../../utils/formatting'
import TransactionDetail from '../../../components/transaction/detail-transaction'
import IModalProps from '../../../interfaces/models/transaction/modal-transaction'

export default function ModalTransaction({
  open,
  title,
  handleOk,
  handleClose,
  details
}: IModalProps) {
  return (
    <Modal
      visible={open}
      title={title}
      onOk={handleOk}
      onCancel={handleClose}
      footer={null}
    >
      <TransactionDetail
        createdAt={details?.createdAt}
        reason={details?.reason}
        email={details?.user?.email}
        noctuaId={details?.userId}
        receiptId={details?.transactionId}
        pointAmount={details?.amount}
        gameCode={onCapitalize(details?.detail?.gameCode || '')}
        serverId={details?.detail?.serverId}
        accountId={onCapitalize(details?.detail?.accountId || '')}
        status={details?.detail?.status}
        notes={details?.detail?.item?.content?.description}
        callbackQsdk={details?.detail?.callbackQsdk}
      />
    </Modal>
  )
}
