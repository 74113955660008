/* eslint-disable react-hooks/exhaustive-deps */
import MainLayout from '../../components/layout'
import { Col, Row } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import actions from '../../store/actions'
import { ReduxState } from '../../store/reducers'
import { IPurchaseHistoryState } from '../../interfaces/states/transaction'
import {
  defaultIParamsLayout,
  IParamsLayout,
  IRequestList
} from '../../interfaces/request/transaction'
import SELL_TYPE from '../../constants/enum/sell-type'
import SelectOptions from '../../components/filter/select-options'
import InputDatePicker from '../../components/filter/input-datepicker'
import InputSearchField from '../../components/filter/input-search-field'
import TableTransactions from '../../components/transaction/tabel-transaction'
import { purchaseHistoryColumns } from '../../constants/table/purchase-history'
import ModalTransaction from './modal'
import RoutesLabel from '../../constants/enum/route-name'
import Text from 'antd/lib/typography/Text'
const TopUp = (props: IProps) => {
  const {
    GetListPurchaseHistory,
    purchaseHistory,
    layout = defaultIParamsLayout,
    sellType = '',
    userID
  } = props
  const initialPage = 1
  const [searchField, setSearchField] = useState<string>('')

  const [startDate, setStartDate] = useState<number>(0)
  const [endDate, setEndDate] = useState<number>(0)
  const [selectedGameOptions, setSelectGameOptions] = useState<string[]>([])
  const [selectedStatusOption, setSelectStatusOptions] = useState<string[]>([])

  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false)
  const [details, setDetails] = useState<any>()

  const onFetchPurchaseHistory = (page: number) => {
    const paramsList = {
      page: page,
      limit: 10,
      search: searchField,
      gameCodes: selectedGameOptions?.join(','),
      statuses: selectedStatusOption?.join(','),
      createdAtFrom: startDate,
      createdAtTo: endDate,
      sellType: sellType as SELL_TYPE,
      userID: userID
    }
    GetListPurchaseHistory(paramsList)
  }

  const onShowingDetailTransaction = (data: any) => {
    setDetails(data)
    setIsShowingDetails(true)
  }

  const onCloseDetailTransaction = () => {
    setIsShowingDetails(false)
    setDetails({})
  }

  useEffect(() => {
    onFetchPurchaseHistory(initialPage)
  }, [selectedGameOptions, selectedStatusOption, startDate, endDate])

  const Filter = () => {
    return (
      <Row
        align='middle'
        justify='space-between'
        gutter={[16, 16]}
        className='mb-2'
      >
        <Col>
          {layout?.title && (
            <Text className='text-size-16 text-color-primary-purple'>
              Transaction History
            </Text>
          )}
        </Col>
        <Col>
          <Row gutter={[8, 8]}>
            <Col>
              <SelectOptions
                onChange={setSelectGameOptions}
                selected={selectedGameOptions}
                filter={'game'}
                placeholder='Select game'
              />
            </Col>
            <Col>
              <SelectOptions
                onChange={setSelectStatusOptions}
                selected={selectedStatusOption}
                filter={'status'}
                placeholder='Select status'
              />
            </Col>
            <Col>
              <InputDatePicker
                onChangeStartDate={setStartDate}
                onChangeEndDate={setEndDate}
              />
            </Col>
            <Col>
              <InputSearchField
                placeholder='search data by: Game Code, User ID, Status, Server ID, Account ID, Receipt ID'
                searchField={searchField}
                onChange={setSearchField}
                onPress={() => onFetchPurchaseHistory(initialPage)}
                width='350px'
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const View = () => {
    return (
      <>
        {layout?.filter && <Filter />}
        <Row>
          <Col span={24}>
            {purchaseHistory && (
              <TableTransactions
                columns={purchaseHistoryColumns({ onShowingDetailTransaction })}
                requesting={purchaseHistory?.requesting}
                dataList={purchaseHistory ? purchaseHistory.list : []}
                pagination={{
                  total: purchaseHistory.paging?.totalData,
                  currentPage: purchaseHistory?.paging?.currentPage
                }}
                onPagination={onFetchPurchaseHistory}
              />
            )}
          </Col>
        </Row>
        <Row>
          {isShowingDetails && details && (
            <ModalTransaction
              open={isShowingDetails}
              title={'Purchase Details'}
              handleClose={() => onCloseDetailTransaction()}
              handleOk={() => onCloseDetailTransaction()}
              details={details}
            />
          )}
        </Row>
      </>
    )
  }

  return layout.usingLayout ? (
    <MainLayout
      header={{ title: RoutesLabel.Topup }}
      breadcrumbs={[{ label: RoutesLabel.TransactionHistory }]}
    >
      <View />
    </MainLayout>
  ) : (
    <View />
  )
}

const mapStateToProps = (state: ReduxState) => ({
  purchaseHistory: state.purchaseHistory
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListPurchaseHistory: (props: IRequestList) =>
    dispatch(actions.Shop.GetListPurchaseHistory(props)),
  GetDetailGachaTokenLog: (id: number) =>
    dispatch(actions.Account.GetDetailGachaTokenLog(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(TopUp)

interface IProps {
  GetListPurchaseHistory: (props: IRequestList) => void
  purchaseHistory: IPurchaseHistoryState
  layout?: IParamsLayout
  sellType?: SELL_TYPE | ''
  userID?: number
}
