/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Button, Col, Input, Row } from 'antd'
import UsersList from './table'
import { SearchOutlined } from '@ant-design/icons'
import Text from 'antd/lib/typography/Text'
import SendItemModal from './send-item-modal'
import USER_ACTION from '../../../constants/user-action'
import IUserState from '../../../interfaces/states/user'
import IAuthState from '../../../interfaces/states/auth'
import { havePermission } from '../../../utils/permission'

const page = 1
const limit = 10

interface IUserListsProps {
  auth: IAuthState
  user: IUserState
  GetUsers: (page?: number, limit?: number, search?: string) => void
}

export default function UserListPage({
  auth,
  user,
  GetUsers
}: IUserListsProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = React.useState<string | undefined>(
    undefined
  )

  const onPress = () => {
    GetUsers(page, limit, searchValue)
  }

  useEffect(() => {
    GetUsers(page, limit, searchValue)
  }, [])

  return (
    <>
      <Row justify='space-between' className='mb-2' align='middle'>
        <Col>
          <Text className='text-size-20 text-color-primary-purple' strong>
            Users
          </Text>
        </Col>
        <Col
          style={{
            display: 'flex',
            gap: 10
          }}
        >
          {havePermission(auth.ruleActivePage.level, USER_ACTION.CREATE) && (
            <Button type='primary' onClick={() => setIsModalOpen(true)}>
              + Send Item
            </Button>
          )}
          <Input
            style={{ width: '200px' }}
            placeholder='search'
            suffix={<SearchOutlined />}
            value={searchValue}
            size='middle'
            onPressEnter={() => onPress()}
            onChange={(e: any) => setSearchValue(e.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <UsersList
            user={user}
            onChangePage={(pn) => {
              GetUsers(pn, 10, searchValue)
            }}
          />
        </Col>
      </Row>
      <SendItemModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </>
  )
}
