import React, { useCallback, useState } from 'react'
import { Modal, Form, InputNumber, Select, Button, Image, message } from 'antd'
import { reason_options } from '../../../constants/enum/reason-type'
import { onCapitalize } from '../../../utils/formatting'
import TextArea from 'antd/lib/input/TextArea'
import { ASSETS_USERINFO } from '../../../constants/assets'
import { CreateGoldLog } from '../../../store/apis/account/gold-log'
import { CreateGachaLog } from '../../../store/apis/account/gacha-token-log'
import { generateTransactionID } from '../../../utils/generator'
import { useParams } from 'react-router-dom'
import { Item } from '../../../constants/enum/item'

interface FormData {
  amount: number
  reason: string
  note?: string
}

interface IProps {
  isOpen: boolean
  itemType: Item
  setIsOpen: (open: boolean) => void
  callback?: () => void
}

export default function ModalSendItem({
  isOpen,
  setIsOpen,
  callback,
  itemType
}: IProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const params: { id: string } = useParams()
  const [form] = Form.useForm()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const handleSendGold = useCallback(
    async (data: FormData) => {
      const currentDate: Date = new Date()
      const timestamp: number = currentDate.getTime()
      const resp = await CreateGoldLog({
        amount: data.amount,
        reason: data.reason,
        transactionId: generateTransactionID(timestamp.toString(), data.reason),
        userId: parseInt(params?.id),
        note: data.note || ''
      })

      if (resp?.status === 200 || resp?.data?.success) {
        message.success(`Successfully sending gold`)
        form.resetFields()
        setIsOpen(false)
        if (callback) callback()
      }
    },
    [params?.id, form, setIsOpen, callback]
  )

  const handleSendTicket = useCallback(
    async (data: FormData) => {
      const currentDate: Date = new Date()
      const timestamp: number = currentDate.getTime()
      const resp = await CreateGachaLog({
        amount: data.amount,
        reason: data.reason,
        transactionId: generateTransactionID(timestamp.toString(), data.reason),
        userId: parseInt(params?.id),
        note: data.note || ''
      })

      if (resp?.status === 200 || resp?.data?.success) {
        message.success(`Successfully sending item`)
        form.resetFields()
        setIsOpen(false)
        if (callback) callback()
      }
    },
    [params?.id, form, setIsOpen, callback]
  )

  const handleSubmitForm = useCallback(
    async (data: FormData) => {
      setIsLoading(true)
      if (itemType === Item.noctuaGold) handleSendGold(data)
      if (itemType === Item.ticket) handleSendTicket(data)
      setIsLoading(false)
    },
    [itemType, handleSendGold, handleSendTicket]
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
    form.resetFields()
  }, [form, setIsOpen])

  return (
    <Modal
      visible={isOpen}
      title={
        <h3 style={{ color: '#0d43fb' }}>
          Send {itemType === Item.noctuaGold ? 'Noctua Gold' : 'Gacha Ticket'}
        </h3>
      }
      onCancel={handleClose}
      closeIcon={null}
      footer={null}
    >
      <Form<FormData>
        onFinish={handleSubmitForm}
        form={form}
        name='transaction'
        scrollToFirstError
        layout='vertical'
      >
        <Form.Item<FormData>
          key='amount'
          name='amount'
          label={
            <div style={{ display: 'flex', gap: 5 }}>
              {itemType === Item.noctuaGold && (
                <>
                  Total Gold{' '}
                  <Image
                    src={'/images/' + ASSETS_USERINFO.NOCTUA_GOLD}
                    width={20}
                    height={20}
                    preview={false}
                  />
                </>
              )}
              {itemType === Item.ticket && (
                <>
                  Total gacha tiket{' '}
                  <Image
                    src={'/images/' + ASSETS_USERINFO.GACHA_TICKET}
                    width={20}
                    height={20}
                    preview={false}
                  />
                </>
              )}
            </div>
          }
          rules={[{ required: true, message: 'Please input amount' }]}
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>
        <Form.Item<FormData>
          key='reason'
          name='reason'
          label='Transaction type'
          rules={[{ required: true, message: 'Select transaction type' }]}
        >
          <Select
            options={reason_options.map((item) => ({
              value: item,
              label: onCapitalize(item).replaceAll('-', ' ')
            }))}
          />
        </Form.Item>
        <Form.Item<FormData> key='note' name='note' label='Notes'>
          <TextArea
            rows={5}
            showCount
            maxLength={100}
            placeholder='write notes here'
            style={{ resize: 'none', borderRadius: '5px' }}
          />
        </Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          style={{ borderRadius: '5px' }}
          disabled={isDisabled}
          loading={isLoading}
        >
          Continue
        </Button>
      </Form>
    </Modal>
  )
}
