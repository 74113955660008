import React from 'react'
import { Col, Row, Image, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import { convertUnixTimestampToDate } from '../../../utils/date'
import { onCapitalize, onFormatGameCodeToGame } from '../../../utils/formatting'
import {
  AmountWithPlusMinusFormat,
  getIconByReason,
  getUsdAmountFromGold
} from '../../../utils/table-formatting'
import Tag from '../../../components/tag'

export const GoldListColumns = ({ onShowingDetailTransaction }: any) => {
  return [
    {
      title: 'Transaction Type',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, value) => (
        <Row gutter={8}>
          <Col>
            <Image
              src={'/images/' + getIconByReason(value?.reason)}
              width={40}
            />
          </Col>
          <Col>
            <Row>
              <Text className='text-size-12 font-semibold'>
                {onCapitalize(value?.reason)}
              </Text>
            </Row>
            <Row>
              <Text className='text-size-12'>
                {convertUnixTimestampToDate(text)}
              </Text>
            </Row>
          </Col>
        </Row>
      ),
      width: 250
    },
    {
      title: 'Receipt ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 150
    },
    {
      title: 'Email / Noctua ID',
      dataIndex: 'email',
      key: 'email',
      render: (text, value) => (
        <Text className='text-size-12'>
          {text ? text : value?.user?.email}/{value.userId}
        </Text>
      ),
      width: 150
    },
    {
      title: `Server ID / Game ID`,
      dataIndex: 'detail',
      key: 'detail',
      render: (text, value) => (
        <Text className='text-size-12'>
          {value?.detail?.serverId || '-'} /{' '}
          {value?.detail?.detail?.gameDevItemId || '-'}
        </Text>
      ),
      width: 120
    },
    {
      title: 'Game',
      dataIndex: 'detail',
      key: 'detail',
      render: (text, value) => (
        <Text className='text-size-12'>
          {onFormatGameCodeToGame(value?.detail?.gameCode || '-')}
        </Text>
      ),
      width: 120
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, value) => (
        <>
          <div>
            <Text className='text-size-12' strong>
              {AmountWithPlusMinusFormat(text, value?.reason)}{' '}
            </Text>
          </div>
          <div>
            {value?.reason !== 'gacha' && (
              <Text className='text-size-12'>
                USD {getUsdAmountFromGold(text)}
              </Text>
            )}
          </div>
        </>
      ),
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'id',
      key: 'id',
      render: (text, value) => <Tag status={value?.detail?.status} />,
      width: 100
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, value) => (
        <Button
          onClick={() => onShowingDetailTransaction(value)}
          type='link'
          className='text-size-12'
        >
          See Details
        </Button>
      ),
      width: 100
    }
  ]
}
