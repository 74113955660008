import { Col, Row, Avatar, Typography } from 'antd'
import * as React from 'react'
import moment from 'moment'

import ENV from '../../utils/environment'
import IUser from '../../interfaces/models/user'

const { Text, Link } = Typography

const UserTableColumns: Array<any> = [
  {
    title: 'Noctua ID',
    key: 'id',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-12 text-height-20'>{record.id}</Text>
        </Row>
      )
    }
  },
  {
    title: 'Nickname',
    key: 'nickname',
    render: (record: IUser) => {
      return (
        <Row>
          <Col span={24}>
            <Row align='middle'>
              <Col flex='40px'>
                <Avatar src={record?.configuration?.image} size={32} />
              </Col>
              <Col flex='auto'>
                <Text className='text-size-12 text-height-20'>
                  {record?.nickname}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }
  },
  {
    title: 'Email',
    key: 'email',
    render: (record: IUser) => {
      return (
        <Link
          key={record.id}
          className='text-size-12 text-height-20'
          href={`${ENV.WEB_FRAGMENT_URL}${record.id}`}
        >
          {record?.email}
        </Link>
      )
    }
  },
  {
    title: 'Birth Date',
    key: 'birthDate',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-12 text-height-20'>
            {moment.unix(record?.birthDate || 0).format('DD MMM, YYYY')}
          </Text>
        </Row>
      )
    }
  },
  {
    title: 'Joined At',
    key: 'joinedAt',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-12 text-height-20'>
            {moment.unix(record?.createdAt || 0).format('DD MMM, YYYY')}
          </Text>
        </Row>
      )
    }
  },
  // {
  //   title: 'Total Noctua Gold Recharge',
  //   key: 'birthDate',
  //   render: (record: IUser) => {
  //     return (
  //       <Row key={record.id}>
  //         <Text className='text-size-12 text-height-20'>
  //           {moment.unix(record?.birthDate || 0).format('DD MMM, YYYY')}
  //         </Text>
  //       </Row>
  //     )
  //   }
  // }
  {
    title: 'Action',
    key: 'id',
    render: (record: IUser) => {
      return (
        <Row key={record.id}>
          <Text className='text-size-12 text-height-20'>
            <a href={`/users/${record.id}`}> See Details</a>
          </Text>
        </Row>
      )
    }
  }
]

export default UserTableColumns
