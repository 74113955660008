import React from 'react'
import { Col, Row, Image, Button } from 'antd'
import Text from 'antd/lib/typography/Text'
import { convertUnixTimestampToDate } from '../../utils/date'
import { onCapitalize } from '../../utils/formatting'
import {
  AmountWithPlusMinusFormat,
  getIconByReason
} from '../../utils/table-formatting'

export const PointListColumns = ({ onShowingDetailTransaction }: any) => {
  return [
    {
      title: 'Transaction Type',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, value) => (
        <Row gutter={8}>
          <Col>
            <Image
              src={'/images/' + getIconByReason(value?.reason)}
              width={40}
            />
          </Col>
          <Col>
            <Row>
              <Text className='text-size-12 font-semibold'>
                {onCapitalize(value?.reason)}
              </Text>
            </Row>
            <Row>
              <Text className='text-size-12'>
                {convertUnixTimestampToDate(text)}
              </Text>
            </Row>
          </Col>
        </Row>
      ),
      width: 200
    },
    {
      title: 'Noctua Point Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, value) => (
        <>
          <div>
            <Text className='text-size-12' strong>
              {AmountWithPlusMinusFormat(text, value?.reason, 'pts')}{' '}
            </Text>
          </div>
        </>
      ),
      width: 100
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionId',
      key: 'transactionId',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 100
    },
    {
      title: 'Email / Noctua ID',
      dataIndex: 'email',
      key: 'email',
      render: (text, value) => (
        <Text className='text-size-12'>
          {text ? text : value?.user?.email}/{value.userId}
        </Text>
      ),
      width: 150
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'n0te',
      render: (text) => <Text className='text-size-12'>{text}</Text>,
      width: 100
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text, value) => (
        <Button
          onClick={() => onShowingDetailTransaction(value)}
          type='link'
          className='text-size-12'
        >
          See Details
        </Button>
      ),
      width: 100
    }
  ]
}
