/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col } from 'antd'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import Text from 'antd/lib/typography/Text'
import actions from '../../../store/actions'
import { ReduxState } from '../../../store/reducers'
import { IMilestoneHistoryState } from '../../../interfaces/states/transaction'
import ModalTransaction from '../modal'
import InputDatePicker from '../../../components/filter/input-datepicker'
import InputSearchField from '../../../components/filter/input-search-field'
import { MilestoneListColumns } from '../../../constants/table/milestone'
import TableTransactions from '../../../components/transaction/tabel-transaction'
import { IRequestList } from '../../../interfaces/request/transaction'

const MilestoneList = (props: IProps) => {
  const initialPage = 1
  const [details, setDetails] = useState<any>()
  const [startDate, setStartDate] = useState<number>(0)
  const [endDate, setEndDate] = useState<number>(0)

  const [searchField, setSearchField] = React.useState<string>('')
  const [isShowingDetails, setIsShowingDetails] = useState<boolean>(false)

  const { GetListMilestoneHistory, milestoneHistory, userID, title, filter } =
    props

  const onFetchMilestoneList = (page: number) => {
    const paramsList = {
      page: page,
      limit: 10,
      search: searchField,
      createdAtFrom: startDate,
      createdAtTo: endDate,
      userID: userID
    }
    GetListMilestoneHistory(paramsList)
  }

  const onShowingDetailTransaction = (data: any) => {
    setDetails(data)
    setIsShowingDetails(true)
  }

  useEffect(() => {
    onFetchMilestoneList(initialPage)
  }, [startDate, endDate])

  return (
    <>
      <Row className='mb-2' justify='space-between'>
        {title && (
          <Col>
            <Text className='text-size-24 text-color-primary-purple'>
              {title}
            </Text>
          </Col>
        )}
        {filter && (
          <Col>
            <Row gutter={16}>
              <Col>
                <InputDatePicker
                  onChangeStartDate={setStartDate}
                  onChangeEndDate={setEndDate}
                />
              </Col>
              <Col>
                <InputSearchField
                  width={'350px'}
                  placeholder={
                    'search data by: User ID, Receipt ID, Type, Amount'
                  }
                  searchField={searchField}
                  onChange={setSearchField}
                  onPress={() => onFetchMilestoneList(initialPage)}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row>
        <Col span={24}>
          {milestoneHistory && (
            <TableTransactions
              columns={MilestoneListColumns({ onShowingDetailTransaction })}
              requesting={milestoneHistory?.requesting}
              dataList={milestoneHistory ? milestoneHistory.list : []}
              pagination={{
                total: milestoneHistory.paging?.totalData,
                currentPage: milestoneHistory?.paging?.currentPage
              }}
              onPagination={onFetchMilestoneList}
            />
          )}
        </Col>
      </Row>
      {isShowingDetails && details && (
        <ModalTransaction
          open={isShowingDetails}
          handleOk={() => setIsShowingDetails(false)}
          handleClose={() => setIsShowingDetails(false)}
          title='Transaction Detail'
          details={details}
        />
      )}
    </>
  )
}

const mapStateToProps = (state: ReduxState) => ({
  ...state,
  milestoneHistory: state.milestoneHistory
})

const mapDispatchToProps = (dispatch: any) => ({
  GetListMilestoneHistory: (props: IRequestList) =>
    dispatch(actions.Shop.GetListMilestoneHistory(props))
})

export default connect(mapStateToProps, mapDispatchToProps)(MilestoneList)

interface IProps {
  GetListMilestoneHistory: (props: IRequestList) => void
  milestoneHistory: IMilestoneHistoryState
  userID?: number
  title?: string
  filter?: boolean
}
