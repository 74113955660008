export enum REASON_TYPE {
  CLAIM_REWARD = 'claim-reward',
  EXCHANGE_REWARD = 'exchange-reward',
  PURCHASE_BONUS = 'purchase-bonus',
  PURCHASE_USE = 'purchase-use',
  PREREGISTER = 'pre-register',
  GACHA = 'gacha',
  REFUND = 'refund',
  TOP_UP = 'topup',
  CALENDAR_EVENT_RETRO = 'calendar-event-retro',
  COMPENSATION = 'compensation',
  EVENT = 'event'
}

export const reason_options = [
  REASON_TYPE.REFUND,
  REASON_TYPE.COMPENSATION,
  REASON_TYPE.EVENT
]
